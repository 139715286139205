import { createSvgIcon } from "@mui/material/utils";

export const User = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 10.8001C12.9548 10.8001 13.8705 10.4208 14.5456 9.74568C15.2207 9.07055 15.6 8.15488 15.6 7.2001C15.6 6.24532 15.2207 5.32964 14.5456 4.65451C13.8705 3.97938 12.9548 3.6001 12 3.6001C11.0452 3.6001 10.1296 3.97938 9.45442 4.65451C8.77929 5.32964 8.40001 6.24532 8.40001 7.2001C8.40001 8.15488 8.77929 9.07055 9.45442 9.74568C10.1296 10.4208 11.0452 10.8001 12 10.8001ZM3.60001 21.6001C3.60001 20.497 3.81728 19.4047 4.23942 18.3856C4.66156 17.3664 5.2803 16.4404 6.06031 15.6604C6.84032 14.8804 7.76633 14.2617 8.78547 13.8395C9.8046 13.4174 10.8969 13.2001 12 13.2001C13.1031 13.2001 14.1954 13.4174 15.2145 13.8395C16.2337 14.2617 17.1597 14.8804 17.9397 15.6604C18.7197 16.4404 19.3385 17.3664 19.7606 18.3856C20.1827 19.4047 20.4 20.497 20.4 21.6001H3.60001Z"
      fill="currentColor"
    />
  </svg>,
  "User"
);
