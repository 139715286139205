import { FC, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { IReduxState } from "../../../redux/app_store";
import { useTranslation } from "react-i18next";
import { Language as LanguageIcon } from "../../../icons/language";
import { Logout as LogoutIcon } from "../../../icons/logout";
import { useLogout } from "../../../hooks/use-logout";
import { LanguagePopover } from "./language-popover";
import { SettingPopover } from "./setting-popover";
import { User as UserIcon } from "../../../icons/user";
import NextLink from "next/link";
import { EAPP_ROUTE } from "../../../enums/route.enum";
import AccountAccordion from "../../account/account-accordion";

interface AccountPopoverProps {
  anchorEl: null | Element;
  onClose?: () => void;
  open?: boolean;
}

export const AccountPopover: FC<AccountPopoverProps> = (props) => {
  const { anchorEl, onClose, open, ...other } = props;
  const logout = useLogout();
  const anchorRef = useRef<any | null>(null);
  const { user } = useSelector((store: IReduxState) => store);
  const { t } = useTranslation();
  const [openPopover, setOpenPopover] = useState<boolean>(false);
  const [openSettingsPopover, setOpenSettingsPopover] =
    useState<boolean>(false);

  const handleCloseSettingsPopover = (): void => {
    setOpenSettingsPopover(false);
  };
  const handleClosePopover = (): void => {
    setOpenPopover(false);
    onClose && onClose();
  };

  const handleOpenPopover = (): void => {
    setOpenPopover(true);
  };

  const handleLogout = () => {
    logout();
  };

  return (
    <>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        keepMounted
        onClose={onClose}
        open={!!open}
        PaperProps={{ sx: { width: 300 } }}
        transitionDuration={0}
        {...other}
      >
        <AccountAccordion profile={user} popoverIsOpen={open} />
        <Divider />
        <Box sx={{ my: 1 }}>
          <MenuItem
            ref={anchorRef}
            component={"a"}
            onClick={handleOpenPopover}
            data-testid="language"
          >
            <ListItemIcon>
              <LanguageIcon fontSize="large" />
            </ListItemIcon>
            <ListItemText
              primary={<Typography variant="body1">{t("language")}</Typography>}
            />
          </MenuItem>

          <Divider />
          <NextLink href={EAPP_ROUTE.SETTING_ACCOUNT} passHref>
            <MenuItem component="a" onClick={onClose}>
              <ListItemIcon>
                <UserIcon fontSize="large" />{" "}
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="body1">
                    {t("user_account_settings")}
                  </Typography>
                }
              />
            </MenuItem>
          </NextLink>
          <Divider />
          {/* <NextLink href={EAPP_ROUTE.SETTING_OUTLET} passHref>
                <MenuItem component="a" onClick={onClose}>
                  <ListItemIcon>
                    <CogIcon fontSize="large" />{" "}
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography variant="body1">
                        {t("outlet_settings")}
                      </Typography>
                    }
                  />
                </MenuItem>
              </NextLink>
              <Divider /> */}
          <MenuItem onClick={handleLogout} data-testid="logout-button">
            <ListItemIcon>
              <LogoutIcon fontSize="large" />{" "}
            </ListItemIcon>
            <ListItemText
              primary={<Typography variant="body1">{t("logout")}</Typography>}
            />
          </MenuItem>
        </Box>
      </Popover>
      <LanguagePopover
        anchorEl={anchorRef.current}
        onClose={handleClosePopover}
        open={openPopover}
      />
      <SettingPopover
        anchorEl={anchorRef.current}
        onClose={handleCloseSettingsPopover}
        open={openSettingsPopover}
      />
    </>
  );
};

AccountPopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
