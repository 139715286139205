import type { FC } from "react";
import PropTypes from "prop-types";
import * as intl from "../../../utils/i18n";
import { useTranslation } from "react-i18next";
import {
  Box,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Typography,
} from "@mui/material";

interface LanguagePopoverProps {
  anchorEl: null | Element;
  onClose?: () => void;
  open?: boolean;
}

type Language = "en" | "sv";

type LanguageOptions = {
  [key in Language]: {
    icon: string;
    label: string;
  };
};

export const LanguagePopover: FC<LanguagePopoverProps> = (props) => {
  const { anchorEl, onClose, open, ...other } = props;
  const { t } = useTranslation();

  const handleChange = async (language: Language): Promise<void> => {
    onClose?.();
    localStorage.setItem("lang", language);
    intl.set(language);
  };

  const languageOptions: LanguageOptions = {
    sv: {
      icon: "/img/sweden-flag.svg",
      label: t("swedish"),
    },
    en: {
      icon: "/img/britain-flag.svg",
      label: t("english"),
    },
  };

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: "center",
        vertical: "bottom",
      }}
      keepMounted
      onClose={onClose}
      open={!!open}
      PaperProps={{ sx: { width: 240 } }}
      transitionDuration={0}
      {...other}
    >
      {(Object.keys(languageOptions) as Language[]).map((language) => (
        <MenuItem
          onClick={() => handleChange(language)}
          key={language}
          data-testid={languageOptions[language].label}
        >
          <ListItemIcon>
            <Box
              sx={{
                display: "flex",
                height: 20,
                width: 20,
                "& img": {
                  width: "100%",
                },
              }}
            >
              <img
                alt={languageOptions[language].label}
                src={languageOptions[language].icon}
              />
            </Box>
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="subtitle2">
                {languageOptions[language].label}
              </Typography>
            }
          />
        </MenuItem>
      ))}
    </Popover>
  );
};

LanguagePopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
