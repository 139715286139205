import { FC } from "react";
import NextLink from "next/link";
import PropTypes from "prop-types";
import {
  Box,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Cog as CogIcon } from "../../../icons/cog";
import { User as UserIcon } from "../../../icons/user";
import { EAPP_ROUTE } from "../../../enums/route.enum";

interface SettingPopoverProps {
  anchorEl: null | Element;
  onClose?: () => void;
  open?: boolean;
}

export const SettingPopover: FC<SettingPopoverProps> = (props) => {
  const { anchorEl, onClose, open, ...other } = props;
  const { t } = useTranslation();

  return (
    <>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        keepMounted
        onClose={onClose}
        open={!!open}
        PaperProps={{ sx: { width: 300 } }}
        transitionDuration={0}
        {...other}
      >
        <Box sx={{ my: 1 }}>
          <NextLink href={EAPP_ROUTE.SETTING_ACCOUNT} passHref>
            <MenuItem component="a" onClick={onClose}>
              <ListItemIcon>
                <UserIcon fontSize="large" />{" "}
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="body1">
                    {t("user_account_settings")}
                  </Typography>
                }
              />
            </MenuItem>
          </NextLink>
          <NextLink href={EAPP_ROUTE.SETTING_OUTLET} passHref>
            <MenuItem component="a" onClick={onClose}>
              <ListItemIcon>
                <CogIcon fontSize="large" />{" "}
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="body1">
                    {t("outlet_settings")}
                  </Typography>
                }
              />
            </MenuItem>
          </NextLink>
        </Box>
      </Popover>
    </>
  );
};

SettingPopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
