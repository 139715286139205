import {
  Button,
  ButtonProps,
  ButtonTypeMap,
  CircularProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

interface IProps extends ButtonProps<ButtonTypeMap["defaultComponent"]> {
  loading?: boolean;
}

const AppButton = (props: IProps) => {
  const classes = useStyles();
  const btnProps = (
    props: IProps
  ): ButtonProps<ButtonTypeMap["defaultComponent"]> => {
    let newP = { ...props };
    delete newP.loading;

    return newP;
  };

  return props.loading ? (
    <Button
      {...btnProps(props)}
      disabled
      className={classes.appButton}
      startIcon={<CircularProgress size={20} />}
    />
  ) : (
    <Button className={classes.appButton} {...btnProps(props)} />
  );
};

export default AppButton;

const useStyles = makeStyles(() => ({
  appButton: {
    borderRadius: "3.2rem",
    zIndex: 1,
  },
}));
