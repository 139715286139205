import type { FC } from "react";
import { useEffect, useMemo, useState } from "react";
import { format } from "date-fns";
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography,
} from "@mui/material";
import { Notification } from "../../../types/notification";
import { Scrollbar } from "../../share/scrollbar";
import { useTranslation } from "react-i18next";
import router from "next/router";
import { apiRequestHandler } from "../../../api/requestHandler";
import { useSelector } from "react-redux";
import { IReduxState } from "../../../redux/app_store";
import { EROLE } from "../../../enums/role.enum";

interface NotificationsPopoverProps {
  anchorEl: null | Element;
  onClose: () => void;
  onUpdateUnread?: (value: number) => void;
  open?: boolean;
  notifications?: any;
  handleReload: () => void;
}

export const NotificationsPopover: FC<NotificationsPopoverProps> = (props) => {
  const { anchorEl, onClose, onUpdateUnread, open, handleReload, ...other } =
    props;

  const [notifications, _] = useState<Notification[]>([]);
  const unread = useMemo(
    () =>
      notifications.reduce(
        (acc, notification) => acc + (notification.read ? 0 : 1),
        0
      ),
    [notifications]
  );
  const { selectedUnit, user } = useSelector((store: IReduxState) => store);
  useEffect(() => {
    onUpdateUnread?.(unread);
  }, [onUpdateUnread, unread]);

  const { t } = useTranslation();

  const handlerInvitation = async (id, operation) => {
    await apiRequestHandler(
      `/api/hospitad/user-management/user/invitations/${id}/${operation}`,
      "POST",
      {},
      {
        "x-user-id": user?.id,
        "x-unit-id": selectedUnit?.id,
        "Content-Type": "application/json",
      }
    );
    handleReload();
  };

  const notificationList = props?.notifications?.notification.map((el) => {
    return { ...el, type: "notification" };
  });
  const invitationList = props?.notifications?.invitation.map((el) => {
    return { ...el, type: "invitation" };
  });
  const list = [...notificationList, ...invitationList].sort((a, b) =>
    a.date < b.date ? 1 : -1
  );
  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: "left",
        vertical: "bottom",
      }}
      onClose={onClose}
      open={!!open}
      PaperProps={{ sx: { width: 380 } }}
      transitionDuration={0}
      {...other}
    >
      <Box
        sx={{
          alignItems: "center",
          backgroundColor: "primary.main",
          color: "primary.contrastText",
          display: "flex",
          justifyContent: "space-between",
          px: 3,
          py: 2,
        }}
      >
        <Typography color="inherit" variant="h6">
          {t("account_page__notifications__tab_title")}
        </Typography>
        {/* <Tooltip title="Mark all as read">
          <IconButton
            onClick={handleMarkAllAsRead}
            size="small"
            sx={{ color: 'inherit' }}
          >
            <MailOpenIcon fontSize="small" />
          </IconButton>
        </Tooltip> */}
      </Box>
      {list?.length === 0 ? (
        <Box sx={{ p: 2 }}>
          <Typography variant="subtitle2">
            {t("account_page__notifications__status__no_notifications")}
          </Typography>
        </Box>
      ) : (
        <Scrollbar sx={{ maxHeight: 400 }}>
          <List disablePadding>
            {list?.map((notification) => {
              return notification?.type === "notification" ? (
                <ListItem
                  divider
                  key={notification.id}
                  sx={{
                    cursor: "pointer",
                    alignItems: "flex-start",
                    "&:hover": {
                      backgroundColor: "action.hover",
                    },
                    "& .MuiListItemSecondaryAction-root": {
                      top: "24%",
                    },
                  }}
                  onClick={() => {
                    props.onClose();
                    router.push(`/invoice/${notification.invoiceId}`);
                  }}
                >
                  <>
                    <ListItemText
                      primary={
                        <Box
                          sx={{
                            alignItems: "center",
                            display: "flex",
                            flexWrap: "wrap",
                            m: 0,
                          }}
                        >
                          <Typography variant="body2" sx={{ mr: 0.5 }}>
                            {`${t(
                              "account_page__notifications__alerts__notification__message__before_invoice_number"
                            )} ${notification.number} ${t(
                              "account_page__notifications__alerts__notification__message__between_invoice_number_and_number_items"
                            )} ${notification.itemsCount} ${t(
                              "account_page__notifications__alerts__notification__message__after_number_items"
                            )}`}
                          </Typography>
                        </Box>
                      }
                      secondary={
                        <Typography color="textSecondary" variant="caption">
                          {format(
                            new Date(notification?.date),
                            "MMM dd, h:mm a"
                          )}
                        </Typography>
                      }
                      sx={{ my: 0 }}
                    />
                  </>
                </ListItem>
              ) : (
                <ListItem
                  divider
                  key={notification?.id}
                  sx={{
                    cursor: "pointer",
                    alignItems: "flex-start",
                    "&:hover": {
                      backgroundColor: "action.hover",
                    },
                    "& .MuiListItemSecondaryAction-root": {
                      top: "24%",
                    },
                  }}
                >
                  <>
                    <ListItemText
                      primary={
                        <Box
                          sx={{
                            alignItems: "center",
                            display: "flex",
                            flexWrap: "wrap",
                            m: 0,
                          }}
                        >
                          <Typography variant="body2" sx={{ mr: 0.5 }}>
                            {`${t(
                              "account_page__outlets_invite__message__before_invite_name"
                            )} ${
                              notification?.role === EROLE.OWNER
                                ? t("outlet_page__user_roles__owner")
                                : notification?.role === EROLE.EDITOR
                                ? t("outlet_page__user_roles__editor")
                                : t("outlet_page__user_roles__reporter")
                            } ${t(
                              "account_page__outlets_invite__message__between_invite_name"
                            )} ${notification?.units[0].name} ${t(
                              "account_page__outlets_invite__message__after_invite_name"
                            )} ${notification?.inviterUserName} `}
                          </Typography>
                          <Box sx={{ display: "flex", my: 1 }}>
                            <Button
                              color="primary"
                              variant="outlined"
                              onClick={() => {
                                handlerInvitation(notification?.id, "decline");
                              }}
                            >
                              {t(
                                "account_page__outlets_invite__buttons__decline"
                              )}
                            </Button>

                            <Button
                              type="submit"
                              variant="contained"
                              sx={{ mx: 2 }}
                              onClick={() => {
                                handlerInvitation(notification?.id, "accept");
                              }}
                            >
                              {t(
                                "account_page__outlets_invite__buttons__accept"
                              )}
                            </Button>
                          </Box>
                        </Box>
                      }
                      secondary={
                        <Typography color="textSecondary" variant="caption">
                          {format(
                            new Date(notification?.date),
                            "MMM dd, h:mm a"
                          )}
                        </Typography>
                      }
                      sx={{ my: 0 }}
                    />
                  </>
                </ListItem>
              );
            })}
          </List>
        </Scrollbar>
      )}
    </Popover>
  );
};
