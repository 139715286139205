import { Badge, IconButton, Tooltip, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { NotificationsPopover } from "./notification-popover";
import { Bell as BellIcon } from "../../../icons/bell";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { IReduxState } from "../../../redux/app_store";
import { useQuery } from "react-query";
/* import { EDEVIATION_STATUS } from "../../deviations/deviationStatus.enum";
import { EQUERY } from "../../../enums/queries.enum";
import router from "next/router"; */
import { apiRequestHandler } from "../../../api/requestHandler";
/* import { objectToQueryString } from "../../../utils/string.utils"; */

export const NotificationsButton: any = ({ sx = {} }) => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  //@ts-ignore
  const [unread, setUnread] = useState<number>(0);
  const [openPopover, setOpenPopover] = useState<boolean>(false);
  const { t } = useTranslation();
  const [list, setList] = useState<any>({ notification: [], invitation: [] });
  // Unread notifications should come from a context and be shared with both this component and
  // notifications popover. To simplify the demo, we get it from the popover
  const theme = useTheme();

  /* const { selectedUnit, selectedOutlet, dateRange, activity, user } =
    useSelector((store: IReduxState) => store); */
  const { user } = useSelector((store: IReduxState) => store);

  /* const previousLogin =
    activity?.attributes?.loginHistory[
      activity?.attributes?.loginHistory.length - 2
    ].split("T")[0];
  const currentDate = new Date().toISOString().split("T")[0];
  const getNotifications: any = useQuery<any>(
    [EQUERY.DEVIATION_INVOICE, selectedOutlet, router.query, dateRange],
    async () => {
      const data: any = await apiRequestHandler(
        `/api/hospitad/clouseau/deviations/outlets/${
          selectedOutlet?.id
        }/invoices?${objectToQueryString({
          page: 1,
          pageSize: 99999,
          sort: "date",
          order: "desc",
          statuses: EDEVIATION_STATUS.UNRESOLVED,
          from: previousLogin,
          to: currentDate,
        })}`,
        "GET",
        {},
        {
          "x-unit-id": selectedUnit?.id,
          "Content-Type": "application/json",
        }
      );

      return data;
    }
  ); */
  const getInvitations: any = useQuery<any>(
    ["USER-INVITES", user],
    async () => {
      const data: any = await apiRequestHandler(
        `/api/hospitad/user-management/user/invitations?states=REQUESTED`,
        "GET",
        {},
        {
          "x-user-id": user?.id,
          "Content-Type": "application/json",
        }
      );
      return data;
    }
  );

  const handleOpenPopover = (): void => {
    setOpenPopover(true);
  };

  const handleClosePopover = (): void => {
    setOpenPopover(false);
    getInvitations.refetch();
  };

  const handleUpdateUnread = (value: number): void => {
    setUnread(value);
  };
  /* useEffect(() => {
    if (
      getNotifications?.data?.content &&
      getNotifications?.data?.content.length > 0
    ) {
      setList({ ...list, notification: getNotifications?.data?.content });
    }
  }, [getNotifications.data]); */
  useEffect(() => {
    if (getInvitations?.data && getInvitations?.data.length > 0) {
      setList({ ...list, invitation: getInvitations?.data });
    }
  }, [getInvitations.data]);
  return (
    (list.notification.length > 0 || list.invitation.length > 0) && (
      <>
        <Tooltip title={`${t("account_page__notifications__tab_title")}`}>
          <IconButton
            ref={anchorRef}
            sx={{ ml: 1 }}
            onClick={handleOpenPopover}
          >
            <Badge
              color="error"
              badgeContent={list.notification.length + list.invitation.length}
              sx={{ span: { fontSize: "1rem", fontWeight: "bold" } }}
            >
              <BellIcon
                style={{
                  color: theme.palette.action.active,
                  fontSize: "22px",
                  ...sx,
                }}
              />
            </Badge>
          </IconButton>
        </Tooltip>
        <NotificationsPopover
          anchorEl={anchorRef.current}
          onClose={handleClosePopover}
          onUpdateUnread={handleUpdateUnread}
          open={openPopover}
          notifications={list}
          handleReload={getInvitations.refetch}
        />
      </>
    )
  );
};
