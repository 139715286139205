import { FC, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  AppBar,
  Box,
  Button,
  FormControlLabel,
  IconButton,
  Modal,
  Switch,
  Toolbar,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import type { AppBarProps } from "@mui/material";
import { AccountButton } from "./navbar-account-button";
import { Menu as MenuIcon } from "../../../icons/menu";
import config from "../../../utils/config";
//import { NotificationsButton } from "./notification-button";
import { useTranslation } from "react-i18next";
//import { InvitesButton } from "./invite-button";
import { NotificationsButton } from "./notification-button";
import { checkImpersonation } from "../../../utils/impersonation";
import { useLogout } from "../../../hooks/use-logout";
import CheckIcon from "@mui/icons-material/Check";
import LogoutIcon from "@mui/icons-material/Logout";
import useAppMediaQuery from "../../../hooks/use-media-query";
import * as intl from "../../../utils/i18n";
import { useSelector } from "react-redux";
import { IReduxState } from "../../../redux/app_store";
import { EONBOARDING_STATUS } from "../../../enums/onboarding-status.enum";

import { addMinutes } from "date-fns";
interface DashboardNavbarProps extends AppBarProps {
  onOpenSidebar?: () => void;
  width: string;
  isOnboarding?: boolean;
  isSettingOutletPage?: boolean;
}

const DashboardNavbarRoot = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  ...(theme.palette.mode === "light"
    ? {
        boxShadow: theme.shadows[3],
      }
    : {
        backgroundColor: theme.palette.background.paper,
        borderBottomColor: theme.palette.divider,
        borderBottomStyle: "solid",
        borderBottomWidth: 1,
        boxShadow: "none",
      }),
}));

const boxWrapper = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: 500,
  bgcolor: "#323232",
  borderRadius: "8px",
  boxShadow: "0px 25px 50px rgba(100, 116, 139, 0.25)",
  p: "6.4rem",
};

const boxContent = {
  textAlign: "center",
  color: "#fff",
};
const boxAction = {
  display: "flex",
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
};

const ImpressonatedModal = ({ open, content, actions }: any) => {
  return (
    <Modal open={open} onClose={close}>
      <Box sx={boxWrapper}>
        <Box sx={boxContent}>{content}</Box>
        <Box sx={boxAction}>{actions}</Box>
      </Box>
    </Modal>
  );
};

const HideFlagSwitcher = ({ sx = {} }) => {
  const { t } = useTranslation();
  const [hideFlag, setHideFlag] = useState(
    localStorage.getItem("hideFlag") === "true" ? false : true
  );

  const handleChange = () => {
    setHideFlag(!hideFlag);
    localStorage.setItem("hideFlag", hideFlag ? "true" : "false");
    window.dispatchEvent(new Event("storage"));
    if (hideFlag) {
      localStorage.setItem("lang", "en");
      intl.set("en");
    } else {
      localStorage.setItem("lang", "disabled");
      intl.set("disabled");
    }
  };

  return (
    <Box sx={{ m: 0 }}>
      <FormControlLabel
        control={<Switch name="hideFlag" checked={hideFlag} />}
        label={t("application_utils__development_flag_label")}
        onChange={() => handleChange()}
        sx={{ color: "text.secondary", ...sx }}
      />
    </Box>
  );
};
export const DashboardNavbar: FC<DashboardNavbarProps> = (props) => {
  const NEXT_PUBLIC_appEnv = config.appEnv;
  const { t } = useTranslation();
  const logout = useLogout();
  const { isSettingOutletPage, onOpenSidebar, width, ...other } = props;
  const { user } = useSelector((store: IReduxState) => store);
  const isImpersonated = checkImpersonation();
  const isMockedData =
    user?.onboardingStatus === EONBOARDING_STATUS.ESIGN_DOCUMENT_SIGNED &&
    !props.isOnboarding;
  const handleLogout = () => {
    logout();
  };
  const isMobile = useAppMediaQuery();
  const [open, setOpen] = useState(
    isImpersonated && !localStorage?.getItem("acceptImpersonation")
      ? true
      : false
  );

  const handleOpen = () => setOpen(true);
  //@ts-ignore
  const handleClose = (event) => {
    setOpen(false);
    localStorage.setItem("acceptImpersonation", "true");
    localStorage.setItem(
      "impersonationEnds",
      addMinutes(new Date(), 30).toISOString()
    );
  };
  useEffect(() => {
    if (
      !open &&
      isImpersonated &&
      !localStorage?.getItem("acceptImpersonation")
    ) {
      handleOpen();
    }
  }, [isImpersonated]);
  return (
    <>
      <DashboardNavbarRoot
        sx={{
          left: {
            lg: width,
          },
          width: {
            lg: `calc(100% - ${width})`,
          },
          backgroundColor: isImpersonated
            ? "#000"
            : isMockedData
            ? "#F24868"
            : "#FFF",
        }}
        {...other}
      >
        <Toolbar
          disableGutters
          sx={{
            minHeight: 64,
            left: 0,
            px: 2,
          }}
        >
          {!props.isOnboarding && (
            <IconButton
              onClick={onOpenSidebar}
              data-testid="menu-icon"
              id="menu-button"
              sx={{
                display: "inline-flex",
                color:
                  isImpersonated || isMockedData ? "#fff" : "text.secondary",
              }}
            >
              <MenuIcon fontSize="large" />
            </IconButton>
          )}
          {isImpersonated && !isMobile && (
            <Typography variant="body2">
              {t("user_impersonation__alert_navbar__message")}
            </Typography>
          )}
          {!isImpersonated && isMockedData && (
            <Typography variant="body2">
              {t("mocked_data__alert_navbar__message")}
            </Typography>
          )}
          <Box sx={{ flexGrow: 1 }} />
          {isImpersonated && (
            <Button
              type="submit"
              variant="contained"
              sx={{ mx: 1 }}
              onClick={handleLogout}
            >
              {isMobile ? (
                <LogoutIcon />
              ) : (
                t("user_impersonation__alert_navbar__button")
              )}
            </Button>
          )}
          {NEXT_PUBLIC_appEnv === "staging" ? (
            <HideFlagSwitcher
              sx={{
                color:
                  isImpersonated || isMockedData ? "#fff" : "text.secondary",
              }}
            />
          ) : null}
          <NotificationsButton
            sx={{
              color: isImpersonated || isMockedData ? "#fff" : "text.secondary",
            }}
          />
          {/*  <InvitesButton /> */}
          <AccountButton />
        </Toolbar>
      </DashboardNavbarRoot>
      <ImpressonatedModal
        open={open}
        close={handleClose}
        content={
          <>
            <Typography variant="h6" sx={{ mb: 2 }}>
              {t("user_impersonation__alert_modal__title")}
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              {t("user_impersonation__alert_modal__description")}
            </Typography>
          </>
        }
        actions={
          <>
            <Button
              variant="outlined"
              color="secondary"
              sx={{ mr: 1 }}
              onClick={() => logout()}
            >
              {t("user_impersonation__alert_modal__button__cancel")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              startIcon={<CheckIcon />}
              onClick={handleClose}
            >
              {t("user_impersonation__alert_modal__button__confirm")}
            </Button>
          </>
        }
      />
    </>
  );
};

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};
