import { createSvgIcon } from "@mui/material/utils";

export const Logout = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.60002 3.60001C3.28176 3.60001 2.97654 3.72643 2.7515 3.95148C2.52645 4.17652 2.40002 4.48175 2.40002 4.80001V19.2C2.40002 19.5183 2.52645 19.8235 2.7515 20.0485C2.97654 20.2736 3.28176 20.4 3.60002 20.4C3.91828 20.4 4.22351 20.2736 4.44855 20.0485C4.6736 19.8235 4.80002 19.5183 4.80002 19.2V4.80001C4.80002 4.48175 4.6736 4.17652 4.44855 3.95148C4.22351 3.72643 3.91828 3.60001 3.60002 3.60001ZM15.9516 14.7516C15.733 14.9779 15.6121 15.2811 15.6148 15.5957C15.6175 15.9103 15.7438 16.2113 15.9662 16.4338C16.1887 16.6563 16.4897 16.7825 16.8043 16.7852C17.119 16.7879 17.4221 16.667 17.6484 16.4484L21.2484 12.8484C21.4734 12.6234 21.5998 12.3182 21.5998 12C21.5998 11.6818 21.4734 11.3766 21.2484 11.1516L17.6484 7.55161C17.5377 7.43699 17.4053 7.34558 17.2589 7.28268C17.1125 7.21979 16.955 7.18669 16.7957 7.18531C16.6364 7.18392 16.4784 7.21428 16.3309 7.27462C16.1834 7.33496 16.0494 7.42406 15.9368 7.53673C15.8241 7.6494 15.735 7.78339 15.6746 7.93086C15.6143 8.07834 15.5839 8.23635 15.5853 8.39569C15.5867 8.55502 15.6198 8.71249 15.6827 8.85889C15.7456 9.0053 15.837 9.13771 15.9516 9.24841L17.5032 10.8H8.40002C8.08176 10.8 7.77654 10.9264 7.5515 11.1515C7.32645 11.3765 7.20002 11.6817 7.20002 12C7.20002 12.3183 7.32645 12.6235 7.5515 12.8485C7.77654 13.0736 8.08176 13.2 8.40002 13.2H17.5032L15.9516 14.7516Z"
      fill="currentColor"
    />
  </svg>,
  "Logout"
);
