import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccountList from "./account-show-list";
import { useSelector } from "react-redux";
import { IReduxState } from "../../redux/app_store";
import jwt_decode from "jwt-decode";
import { useTranslation } from "react-i18next";
import { checkImpersonation } from "../../utils/impersonation";

export default function AccountAccordion({ profile, popoverIsOpen }) {
  const isImpersonated = checkImpersonation();
  const [display, setDisplay] = useState<boolean>(false);
  const [showList, setShowList] = useState<boolean>(
    isImpersonated ? true : false
  );
  const { user } = useSelector((store: IReduxState) => store);
  const [currentAccId, setCurrentAccId] = useState<string>(
    jwt_decode(localStorage.getItem("accessToken")!)![
      "https://parsly.com/account_id"
    ]
  );
  const [accountName, setAccountName] = useState<string>("");
  const { t } = useTranslation();
  useEffect(() => {
    setDisplay(false);
    const token = jwt_decode(localStorage.getItem("accessToken")!);
    setCurrentAccId(token!["https://parsly.com/account_id"]);
    if (user?.accounts && user?.accounts?.length > 1) {
      let accountEndName = currentAccId?.substring(currentAccId.length - 4);
      let accountName2 = `${t("account_name_label")}`;
      if (accountEndName !== undefined) {
        accountName2 = `${t("account_name_label")} ${accountEndName}`;
      }
      const getName = user?.accounts?.find((el) => el.id === currentAccId)
        ?.name!;
      accountName2 = getName ? getName : accountName2;

      setShowList(true);
      setAccountName(accountName2);
    }
  }, [popoverIsOpen]);

  return (
    <Accordion
      expanded={display}
      sx={{ boxShadow: "none", "&.Mui-expanded": { margin: 0 } }}
    >
      <AccordionSummary
        expandIcon={showList && <ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{
          borderBottom: display ? "1px solid #E6E8F0" : "none",
          "&.MuiAccordionSummary-root": {
            cursor: showList ? "pointer" : "default!important",
          },
          "& .Mui-expanded": { margin: "1.2rem 0" },
        }}
        onClick={() => showList && setDisplay(!display)}
      >
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
          }}
        >
          <Avatar
            data-testid="user-avatar-popover"
            src={profile?.picture}
            sx={{
              height: 40,
              width: 40,
            }}
          ></Avatar>
          <Box
            sx={{
              ml: 1,
            }}
          >
            <Typography variant="body1" data-testid="full-name">
              {profile?.fullName}
            </Typography>
            <Typography
              color="textSecondary"
              variant="body2"
              data-testid="user-email"
            >
              {profile?.email}
            </Typography>
            {showList && accountName !== "" && (
              <Typography
                color="textSecondary"
                variant="caption"
                data-testid="user-email"
              >
                {accountName}
              </Typography>
            )}
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        {display && showList && <AccountList currentAccId={currentAccId} />}
      </AccordionDetails>
    </Accordion>
  );
}
