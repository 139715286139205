import { Avatar, Box, CircularProgress, Typography } from "@mui/material";
import React, { useState } from "react";
import { apiRequestHandler } from "../../api/requestHandler";
import { useDispatch, useSelector } from "react-redux";
import { IReduxState } from "../../redux/app_store";
import { setSelectedOutletAction } from "../../redux/action/selected-outlet";
import { setOutletsAction } from "../../redux/action/outlets";
import { useTranslation } from "react-i18next";
var stringToColour = function (str) {
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  var colour = "#";
  for (var i = 0; i < 3; i++) {
    var value = (hash >> (i * 8)) & 0xff;
    colour += ("00" + value.toString(16)).substr(-2);
  }
  return colour;
};
export default function AccountList({ currentAccId }) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { user } = useSelector((store: IReduxState) => store);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const sortedList: any = user?.accounts
    .sort((a, b) => a.id.localeCompare(b.id))!
    .filter((el) => el.id !== currentAccId)
    .map((el) => ({
      ...el,
      name: el?.name
        ? el?.name
        : `${t("account_name_label")} ${el.id.substring(el.id.length - 4)}`,
    }));

  const handleSelectClick = async (accountId) => {
    setIsLoading(true);
    const data: any = await apiRequestHandler(
      `/api/service/switch-account`,
      "POST",
      {
        preferredAccountId: accountId,
      },
      {
        "Content-Type": "application/json",
      }
    );
    if (data?.token) {
      dispatch(setSelectedOutletAction(null!));
      dispatch(setOutletsAction([]));

      localStorage.setItem("accessToken", data?.token!);
      window.location.replace("/");
    }
  };
  return (
    <Box
      sx={{
        width: "100%",
        maxHeight: "14rem",
        overflowY: isLoading ? "hidden" : "scroll",
        overflowX: "hidden",
      }}
    >
      {isLoading ? (
        <Box sx={{ display: "flex", justifyContent: "center", py: 3 }}>
          <CircularProgress />
        </Box>
      ) : (
        sortedList.map((el, i) => {
          return (
            <Box
              sx={{
                height: "6rem",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                p: "1rem 0.5rem 1rem 1rem",
                "&:hover": {
                  backgroundColor: "#f7f7f7",
                },
              }}
              key={`account-list-el-${i}`}
              onClick={() => handleSelectClick(el.id)}
            >
              <Avatar
                data-testid="user-avatar-popover"
                sx={{
                  height: 40,
                  width: 40,
                  mr: 2,
                  backgroundColor: stringToColour(el.id),
                }}
              >
                {" "}
              </Avatar>
              <Box>
                <Typography variant="subtitle2" data-testid="full-name">
                  {el?.name}
                </Typography>
                <Typography
                  color="textSecondary"
                  variant="caption"
                  data-testid="user-email"
                >
                  {el?.id}
                </Typography>
              </Box>
            </Box>
          );
        })
      )}
    </Box>
  );
}
